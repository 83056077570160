import { parseGraphqlBoolean as gBool } from './parsers/graphql/parseGraphqlBoolean';
import { parseGraphqlImage as gImage } from './parsers/graphql/parseGraphqlImage';
import { parseGraphqlItems as gItems } from './parsers/graphql/parseGraphqlItems';
import {
    parseGraphqlLink as gLink,
    parseGraphqlCta as gCta,
} from './parsers/graphql/parseGraphqlLink';
import { parseGraphqlRichText as gRich } from './parsers/graphql/parseGraphqlRichText';
import { parseGraphqlSelect as gSelect } from './parsers/graphql/parseGraphqlSelect';
import { parseGraphqlString as gString } from './parsers/graphql/parseGraphqlString';
import { parseGraphqlTitle as gTitle } from './parsers/graphql/parseGraphqlTitle';
import { parseBoolean as bool } from './parsers/parseBoolean';
import { parseImage as image } from './parsers/parseImage';
import { parseItems as items } from './parsers/parseItems';
import { parseLink as link, parseCta as cta } from './parsers/parseLink';
import { parseRichText as rich } from './parsers/parseRichText';
import { parseSelect as select } from './parsers/parseSelect';
import { parseString as string } from './parsers/parseString';
import { parseTitle as title } from './parsers/parseTitle';

export * from './types/graphql';
export * from './types/prismic';
export * from './types/return';

export * from './helpers/hasValue';
export * from './parsers/parseImage';
export * from './parsers/parseTitle';

export const parsers = {
    bool,
    image,
    items,
    link,
    rich,
    select,
    string,
    title,
    cta,
};

export const parseBoolean = bool;
export const parseImage = image;
export const parseItems = items;
export const parseLink = link;
export const parseRichText = rich;
export const parseSelect = select;
export const parseString = string;
export const parseTitle = title;
export const parseCta = cta;

export const parsersGraphql = {
    bool: gBool,
    image: gImage,
    items: gItems,
    link: gLink,
    rich: gRich,
    select: gSelect,
    string: gString,
    title: gTitle,
    cta: gCta,
};

export const parseGraphqlBoolean = gBool;
export const parseGraphqlImage = gImage;
export const parseGraphqlItems = items;
export const parseGraphqlLink = gLink;
export const parseGraphqlRichText = gRich;
export const parseGraphqlSelect = gSelect;
export const parseGraphqlString = gString;
export const parseGraphqlTitle = gTitle;
export const parseGraphqlCta = gCta;

import { type ProtonURL, addLocale } from '@protonme/routing';
import type { NavigateFn } from './types';

export const changeCurrentLocale = (
    currentUrl: ProtonURL,
    navigate: NavigateFn,
    locale: string,
    ref?: string,
) => {
    if (currentUrl.isPreview) {
        return;
    }
    // we need to store ref param so useUrlWithRef can remove from user visibility
    // and add in analytics beacon
    if (ref) {
        localStorage.setItem('ref', ref);
    }

    const newUrl = addLocale(currentUrl, locale, true);
    const href = newUrl.href.replace(newUrl.origin, '');
    void navigate(href, { state: { ref } });
};

import type { AtomsProps } from '@protonme/atoms';
import type { TrustedHtml } from '@protonme/ui/components/RichText';
import { hasValue } from '../../helpers/hasValue';
import type { GraphqlPrismicTitle } from '../../types/graphql';
import type { HtmlHeading } from '../../types/return';
import { removeTag, titleMapping } from '../parseTitle';
import { parseTrustedHtml } from '../parseTrustedHtml';

const headingRegex = /^<(h[1-6])>/;

export const parseGraphqlTitle = (
    node: GraphqlPrismicTitle | null | undefined,
    limits: HtmlHeading[],
    defaultLevel: HtmlHeading,
): AtomsProps.Title | undefined => {
    if (!node) {
        return undefined;
    }

    if (node.html && hasValue(node.html)) {
        const content = removeTag(node.html);
        if (node.type) {
            const htmlLevel = titleMapping[node.type];
            const level = `h${htmlLevel}` as const;
            if (limits.includes(level)) {
                return {
                    level,
                    content: parseTrustedHtml(content, undefined) || ('' as TrustedHtml),
                };
            }
        }
        const result = headingRegex.exec(node.html);
        if (result !== null) {
            const htmlLevel = result[1] as HtmlHeading;
            if (limits.includes(htmlLevel)) {
                return {
                    level: htmlLevel,
                    content: parseTrustedHtml(content, undefined) || ('' as TrustedHtml),
                };
            }
        }
        return {
            level: defaultLevel,
            content: parseTrustedHtml(content, undefined) || ('' as TrustedHtml),
        };
    }

    if (node.text) {
        return {
            level: defaultLevel,
            content: parseTrustedHtml(node.text, undefined) || ('' as TrustedHtml),
        };
    }

    return undefined;
};

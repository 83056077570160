import type { TrustedHtml } from '@protonme/ui/components/RichText';
import { hasValue } from '../../helpers/hasValue';
import type { GraphQLPrismicRichText } from '../../types/graphql';
import type { FrameworkValue } from '../../types/return';
import { parseTrustedHtml } from '../parseTrustedHtml';

export const parseGraphqlRichText = (
    node: GraphQLPrismicRichText | null | undefined,
    context: FrameworkValue | undefined,
): TrustedHtml | undefined => {
    if (!node) {
        return undefined;
    }
    if (hasValue(node.html)) {
        return parseTrustedHtml(node.html as string, context);
    }
    if (hasValue(node.text)) {
        return parseTrustedHtml(node.text as string, context);
    }

    return undefined;
};

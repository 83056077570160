import { fetchHelper } from '../../helpers/fetchHelper';
import type { SlimAPI_VpnLocation } from './types';

/**
 * @api /api/vpn/location
 * @description Proton Slim API response containing basic visitor location information.
 * @docs https://protonmail.gitlab-pages.protontech.ch/Slim-API/vpn/#tag/VPN/operation/get_vpn-location
 */
export const getVpnLocation = async () => {
    const response = await fetchHelper('/vpn/location');

    // in case of 4xx
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const json: SlimAPI_VpnLocation = await response.json();
    return json;
};
